<template>
    <section>
        <PageHeader>
            <h1>{{ $t("SignIn.LoginButtonText") }}</h1>
        </PageHeader>
        <div class="container">
            <LoginForm class="my-8 max-w-screen-xs" @success="onSuccess" />
        </div>
    </section>
</template>
<script setup lang="ts">
const initializeState = useInitializeState();
const localePath = useLangPath();

async function onSuccess(response: LogInResponse) {
    await initializeState();
    navigateTo(localePath(response.ReturnUrl || "/"));
}
</script>
